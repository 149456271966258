import React, { useContext, useState } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import colors from '../themes/main-colors';
import LeftNavMenu from '../components/left-menu-navigation';
import BreadCrumb from '../components/pge-breadcrumb';
import {
  renderPageComponents,
  getHeroComponent,
  getSplitHeaderComponent,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import './css/contentful-pages.css';
import { Field } from '../contentful.d';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      width: '100%',
      '& .ContentfulElementColumns2 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
      '& .ContentfulElementColumns3 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
    },
    header: {
      fontFamily: 'Forma-DJR-Display',
      '& .hero-container': {
        minHeight: 320,
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
          minHeight: 'auto',
        },
      },
    },
    menu: {
      backgroundColor: colors.white,
      padding: theme.spacing(2.5, 7.5, 0, 0),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    heading: {
      fontFamily: 'Forma-DJR-Display',
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    description: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 'bold',
      fontFamily: 'Forma-DJR-Display',
      letterSpacing: '0.720px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1.25),
      },
    },
    image: {
      width: '100%',
      height: 275,
      borderRadius: 5,
      marginBottom: theme.spacing(2.5),
      objectFit: 'cover',
    },
  }),
);

export const BasicTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'];

  // ** Start temporary rollback of localization for public pages **
  const basicPageData = data.allContentfulPageBasic?.nodes[0];
  // const basicPageData =
  //   data.allContentfulPageBasic?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPageBasic?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  const TEMPLATE = basicPageData?.pageTemplate as Field['Template'];

  if (page?.sectionNavArray && page?.sectionNavArray?.length > 0) {
    page.sectionNavArray[0]['menuOpen'] = true;
  }
  const contentBodyHeaderType =
    basicPageData?.contentEntries &&
    basicPageData?.contentEntries[0]?.__typename;
  let header;
  if (
    contentBodyHeaderType === 'ContentfulHeaderSplit' ||
    contentBodyHeaderType === 'ContentfulHeaderhero'
  ) {
    header =
      contentBodyHeaderType === 'ContentfulHeaderhero'
        ? getHeroComponent([basicPageData?.contentEntries[0]])
        : getSplitHeaderComponent(basicPageData?.contentEntries[0]);
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{basicPageData?.browserTitle || basicPageData?.pageTitle}</title>
        {basicPageData?.metaDescriptionSeo && (
          <meta name="description" content={basicPageData.metaDescriptionSeo} />
        )}
        {basicPageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      {header && <div className={classes.header}>{header}</div>}
      <Container className="component-container">
        {TEMPLATE === 'Left Nav' &&
          page?.sectionNavArray &&
          page?.sectionNavArray?.length > 0 && (
            <div className={classes.menu}>
              <LeftNavMenu
                leftNavMenuItems={page.sectionNavArray}
                selectedItem={page.slug}
              />
            </div>
          )}
        <div className={classes.root}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(basicPageData?.statusAlert)}

          {!header && (
            <div>
              {basicPageData?.pageTitle && (
                <h1 className={classes.heading}>{basicPageData?.pageTitle}</h1>
              )}
              {basicPageData?.pageSubtitle && (
                <div className={classes.description}>
                  {basicPageData?.pageSubtitle}
                </div>
              )}
              {basicPageData?.image?.file?.url && (
                <img
                  className={classes.image}
                  src={basicPageData?.image?.file?.url}
                  height="275"
                  width="800"
                />
              )}
            </div>
          )}
          {renderPageComponents({
            entriesData: basicPageData?.contentEntries || null,
            pageTemplate: basicPageData?.pageTemplate,
          })}
        </div>
      </Container>
    </>
  );
};

export default BasicTemplate;

export const query = graphql`
  query PageBasic($slug: String!) {
    allContentfulPageBasic(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        pageTitle
        pageSubtitle
        pageShortDescription
        pageTemplate
        browserTitle
        metaDescriptionSeo
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        image {
          file {
            contentType
            fileName
            url
          }
        }
        contentEntries {
          ...ElementJumpLinks
          ...ModuleContentBody
          ...ModuleNote
          ...ModuleStatusAlert
          ...ModuleCallOut
          ...ModuleColumn2
          ...ModuleColumn3
          ...ModuleContact
          ...ModuleCallToAction
          ...ModuleMessagingBlock
          ...ModulePullQuote
          ...ModuleSplitHeader
          ...ModuleHeroHeader
          ...ModuleAccordion
          ...ModuleContentList
          ...ModuleDocumentList
          ...GallerySlider
          ...ModulePromoGroup
          ...ModuleRelated
          ...ModuleTabSet
          ...ModuleTOC
          ...ModuleFormWrapper
          ...ModuleImageWrapper
          ...ModuleEmbedMediaWrapper
          ...ProgramCard
          ...StaticList
          ...CardLayout
          ...CTACard
          ...ModuleTable
          ...SocialFeed
          ...Column4
          ...CerosExperience
          ...RealTimePublishing
        }
        statusAlert {
          ...ModuleStatusAlert
        }
      }
    }
  }
`;
